// Vendors

import { AxiosError, AxiosRequestConfig, Method } from "axios";
import { URLSearchParams } from "url";

import { searchItemLocal } from "../utils/auth";
import API from "./api";
import { localKey } from "config/constants";

const HTTP_STATUS_CODE = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
};

export type RequestOptionsType = {
  method?: Method;
  data?: any;
  authRequired?: boolean;
  retry?: boolean;
  params?: Record<string, any> | URLSearchParams;
  responseType?: AxiosRequestConfig["responseType"];
  headers?: any;
};

const getHeaders = (authRequired: boolean) => {
  const headers = authRequired ? searchItemLocal(localKey) : null;
  if (headers) {
    const { access, ...infoHeaders } = headers;
    return {
      Authorization: `Bearer ${access.token}`,
      ...infoHeaders,
    };
  }
  return {};
};

type RequireOne<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export type AxiosErrorWithResponse<T = any> = RequireOne<
  AxiosError<T>,
  "response"
>;

export type AxiosResultErrorCustomHttp<C extends string, T = any> = {
  code: C;
  error: AxiosErrorWithResponse;
  status: number;
  data?: T;
};

type AxiosResultErrorHttp<T = any> = {
  code: "HTTP_ERROR";
  error: AxiosErrorWithResponse;
  status: number;
  data?: T;
};

type AxiosResultErrorConnection = {
  code: "CONNECTION";
  error: AxiosError;
  status?: number;
  data?: null;
};

export type AuthenticationError = {
  code: "AUTHENTICATION";
  error: AxiosErrorWithResponse;
  status: number;
  data?: any;
};

export type AxiosResultDefaultError =
  | AxiosResultErrorHttp
  | AxiosResultErrorConnection;

export type AxiosResult<
  T,
  E extends { code: string; data?: any } = AxiosResultDefaultError
> =
  | { data: T; error?: false; status?: number }
  | { data?: null; error: E; status?: null };

const request = async <T = any>(
  url: string,
  options: RequestOptionsType = {}
): Promise<AxiosResult<T>> => {
  const {
    method = "get",
    data = {},
    authRequired = true,
    headers = {},
    params,
    responseType,
  } = options;
  try {
    const response = await API.request<T>({
      url: url,
      params,
      method,
      data,
      headers: getHeaders(authRequired),
      responseType,
    });
    return { data: response.data, error: false, status: response.status };
  } catch (error: any) {
    if (error.response) {
      return {
        error: {
          code: "HTTP_ERROR",
          status: error.response.status,
          data: error.response.data,
          error,
        },
      };
    }
    return {
      error: {
        code: "CONNECTION",
        error,
      },
    };
  }
};

export { HTTP_STATUS_CODE };
export default request;
