import request from "api/request2";
import { PermissionsResponse } from "./type";

const PERMISSIONS_MAINTAINER = {
  DATA: "/permissions-and-holidays",
};

export function getData() {
  return request<PermissionsResponse>(PERMISSIONS_MAINTAINER.DATA, {
    method: "GET",
  });
}
